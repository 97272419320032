<template>
    <div
        class="bank-product-area--questions-item question row"
        :class="{'is-loading': redirectLoading === question.id}"
    >
        <div class="is-loading-wrapper" v-if="redirectLoading === question.id">
            <CustomLoader />
        </div>
        <div class="col-lg-7 pe-15 question-text-wrapper">
            <div class="question-header">
                <span class="title">{{ $t("Question") }} {{ questionIndex + 1 }}</span>
                <span class="question-header__phase">
                  {{ question.productArea }} <template v-if="question.phase">| {{ question.phase }}</template>
                </span>
            </div>
            <span class="question-text">{{ question.question }}</span>
            <div class="question-chart">
                <CustomBarChart
                    :score="question.chartData.score"
                    :importance="question.chartData.importance"
                    :gap="question.chartData.gap"
                    :positive="question.chartData.positive"
                />
            </div>
        </div>
        <div class="col-lg-5 question-info-wrapper" v-if="!isSorting">
            <div class="question--id">
                ID <b>{{ isSurveyQuestion ? question.question_id : question.id }}</b>
            </div>
            <QuestionInfo :question="question" />
        </div>
    </div>
</template>

<script>
import CustomBarChart from "@/buying-teams/shared-components/charts/CustomBarChart";
import QuestionInfo from "@/buying-teams/pages/bank/dashboard/components/QuestionInfo";
import {
    CustomQuestionsAnalyticsScoreImportanceModel
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsScoreImportanceModel";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";

export default {
    name: "QuestionScoreImportanceItem",
    components: {
        CustomLoader,
        QuestionInfo,
        CustomBarChart
    },
    props: {
        question: {
            type: [Object, CustomQuestionsAnalyticsScoreImportanceModel],
            required: true
        },
        isSorting: Boolean,
        questionIndex: {
            type: Number,
            default: 0
        },
        isSurveyQuestion: {
            type: Boolean,
            default: false,
        },
        redirectLoading: {
            type: Number,
            default: null,
        },
    },
}
</script>

<style scoped lang="scss">
.question {
    margin: 26px 0;
    position: relative;
    cursor: pointer;

    &:after {
        background: #F6F7FF;
        content: "";
        position: absolute;
        top: -8px;
        left: 0;
        bottom: -11px;
        right: 0;
        z-index: 1;
        border-radius: 10px;
        opacity: 0;
        visibility: hidden;
    }

    &.is-loading {
        position: relative;
        z-index: 3;
        cursor: default;

        &:after {
            background: #fff0;
            opacity: 1;
            visibility: visible;
            z-index: 5;
            outline: 1px solid #ccc;
        }

        .is-loading-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 4;
        }
    }

    &:hover:after {
        visibility: visible;
        opacity: 1;
    }

    > div {
        position: relative;
        z-index: 2;
    }

    &-header {
        font-size: 11px;
        font-weight: 700;
        margin-bottom: 11px;

        .title {
            font-size: 11px;
            font-weight: 400;
            color: #757575;
            opacity: 0.5;
            margin-right: 19px;
        }

        &__phase {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #030303;
        }

        .level {
            border-radius: 4px;
            padding: 4px 8px;
            margin-right: 4px;

            &.status-danger {
                background: rgba(226, 45, 33, 0.1);
                color: #E22D21;
            }

            &.status-warning {
                background: rgba(254, 249, 231, 0.5);;
                color: #E6B25F;
            }

            &.status-success {
                background: #DCFFEA;
                color: #0E9B2D;
            }
        }

        .question-type {
            color: #000000;
            padding: 4px 8px;
            background: #ECECEC;
            border-radius: 4px;
        }
    }

    &-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #2A2A2A;
    }

    &-chart {
        margin-top: 24px;
    }

    .question-info-wrapper {
        align-self: flex-end;

        .question--id {
            text-align: right;
            margin-bottom: 20px;
        }

        @media(max-width: 1280px) {
            align-self: flex-start;
            margin: 24px 0px;
            width: 100%;
        }
    }

    .question-text-wrapper {
        @media(max-width: 1280px) {
            width: 100%;
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }

    @media(max-width: 1280px) {
        flex-direction: column;
        //align-self: flex-start;
    }
}
</style>
