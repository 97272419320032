<template>
    <div class="question-text-only-item" :class="{'is-loading': redirectLoading === question.id}">
        <div class="is-loading-wrapper" v-if="redirectLoading === question.id">
            <CustomLoader />
        </div>

        <div class="question-text-only-item__header">
            <div class="question-header-info">
                <span>{{ $t('Question') }} {{ questionIndex + 1 }}</span>
                <strong>{{ question.productArea }}</strong>
            </div>
            <div class="question-header-id">
                <span>{{ $t("Question ID") }}</span>
                <strong>{{ isSurveyQuestion ? question.question_id : question.id }}</strong>
            </div>
        </div>
        <div class="question-text-only-item__content">
            <div class="question-content-text">{{ question.question }}</div>
            <div class="question-content-comment">
                <img src="/media/buying/icons/comment-blue.svg" width="14" alt="">
                <span>{{ question.answers }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {
    CustomQuestionsAnalyticsTextOnlyModel
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsTextOnlyModel";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";

export default {
    name: "QuestionTextOnlyItem",
    components: {
        CustomLoader
    },
    props: {
        question: {
            type: [Object, CustomQuestionsAnalyticsTextOnlyModel],
            required: true
        },
        questionIndex: {
            type: Number,
            default: 0
        },
        isSurveyQuestion: {
            type: Boolean,
            default: false,
        },
        redirectLoading: {
            type: Number,
            default: null,
        },
    },
}
</script>

<style scoped lang="scss">
.question-text-only-item {
    margin: 16px 0;
    padding: 0 10px;
    position: relative;
    cursor: pointer;

    &:after {
        background: #F6F7FF;
        content: "";
        position: absolute;
        top: -8px;
        left: 0;
        bottom: -11px;
        right: 0;
        z-index: 1;
        border-radius: 10px;
        opacity: 0;
        visibility: hidden;
    }

    &:hover:after {
        visibility: visible;
        opacity: 1;
    }

    > div {
        position: relative;
        z-index: 2;
    }

    &.is-loading {
        position: relative;
        z-index: 3;
        cursor: default;

        &:after {
            background: #fff0;
            opacity: 1;
            visibility: visible;
            z-index: 5;
            outline: 1px solid #ccc;
        }

        .is-loading-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 4;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        .question-header-info {
            span {
                font-size: 11px;
                font-weight: 400;
                color: #757575;
                opacity: 0.5;
                margin-right: 19px;
            }
            strong {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #030303;
            }
        }
        .question-header-id {
            font-size: 12px;
            line-height: 158.023%;

            span {
                color: #969696;
                margin-right: 4px;
            }
            strong {
                color: #0A0A0A;
                font-weight: 600;
            }
        }
    }
    &__content {
        display: flex;
        justify-content: space-between;
        .question-content-text {
            width: 90.8%;
            color: #2A2A2A;
            font-size: 16px;
            font-weight: 600;
            line-height: 29.124px;
            letter-spacing: 0.1px;
            padding-right: 10px;
        }
        .question-content-comment {
            cursor: pointer;
            width: 9.2%;
            height: 76px;
            min-width: 76px;
            border-radius: 5px;
            background: #F6F7FF;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            transition: .2s ease-in-out;
            span {
                color: #202021;
                font-size: 16px;
                font-weight: 700;
                line-height: 12.654px;
            }

            &:hover {
                background: #435BF4;
                span {
                    color: #fff;
                }
                img {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
}
</style>
