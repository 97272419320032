<template>
    <div class="options-bar-chart">
        <apexchart type="bar" :options="chartOptions" :series="chartData.series" :height="chartHeight"></apexchart>
    </div>
</template>

<script>
import { COLORS_FOR_OPTIONS } from "@/core/config/constants";

export default {
    name: "OptionsBarChart",
    props: {
        chartData: Object,
        chartHeight: {
            type: String,
            default: '300px'
        },
        showLegend: {
            type: Boolean,
            default: true,
        },
        showDataLabels: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        chartOptions() {
            return {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    show: this.showLegend,
                    position: 'top',
                    horizontalAlign: 'left',
                    markers: {
                        fillColors: [
                            COLORS_FOR_OPTIONS.COLOR_1,
                            COLORS_FOR_OPTIONS.COLOR_2,
                            COLORS_FOR_OPTIONS.COLOR_3,
                            COLORS_FOR_OPTIONS.COLOR_4
                        ],
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 4,
                        columnWidth: this.chartData.categories.length * 10 + 'px',
                        dataLabels: {
                            position: 'top'
                        }
                    },
                },
                dataLabels: {
                    enabled: this.showDataLabels,
                    formatter: function (val) {
                        return val + '%'
                    },
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                yaxis: {
                    min: 0,
                    max: 100,
                    tickAmount: 4,
                    style: {
                        fontSize: '16px',
                        fontWeight: 500,
                        colors: ['#2A2A2A']
                    },
                },
                xaxis: {
                    show: false,
                    categories: this.chartData.categories,
                },
                fill: {
                    opacity: 1,
                    colors: [
                        COLORS_FOR_OPTIONS.COLOR_1,
                        COLORS_FOR_OPTIONS.COLOR_2,
                        COLORS_FOR_OPTIONS.COLOR_3,
                        COLORS_FOR_OPTIONS.COLOR_4
                    ],
                },
                tooltip: {
                    custom: function({ seriesIndex, dataPointIndex, w }) {
                        let value = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                        let name = w.globals.initialSeries[seriesIndex].name

                        return `<div class="options-bar-chart__tooltip">
                            <span>${name}</span>
                            <strong>${value}%</strong>
                        </div>`;
                    }
                },
                grid: {
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                    strokeDashArray: 0,
                },
                responsive: [
                    {
                        breakpoint: 768,
                        options: {
                            dataLabels: {
                                enabled: false,
                            },
                            plotOptions: {
                                bar: {
                                    columnWidth: this.chartData.categories.length * 20 + 'px',
                                },
                            },
                        }
                    }
                ]
            }
        }
    }
}
</script>

<style lang="scss">
.options-bar-chart {
    .apexcharts-text tspan {
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #2A2A2A;
    }
    .apexcharts-legend {
        display: flex;
        align-items: center;
        gap: 48px;
    }
    .apexcharts-legend-series {
        display: flex !important;
        align-items: center;
        margin: 0 !important;
        .apexcharts-legend-marker {
            margin-right: 16px;
            width: 38px !important;
            height: 19px !important;
            border-radius: 4px !important;
        }
        .apexcharts-legend-text {
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: #2A2A2A !important;
        }
    }

    @media (max-width: 768px) {
        .apexcharts-legend {
            gap: 20px;
        }
        .apexcharts-legend-series {
            .apexcharts-legend-marker {
                margin-right: 8px;
                width: 19px !important;
                height: 14px !important;
            }
            .apexcharts-legend-text {
                font-size: 12px !important;
            }
        }
    }

    &__tooltip {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
        padding: 10px;
        span {
            color: #2A2A2A;
            text-align: center;
            font-size: 12.182px;
            font-weight: 700;
            line-height: 15.414px;
            margin-right: 4px;
        }
        strong {
            color: #435BF4;
            text-align: center;
            font-size: 12px;
            font-weight: 700;
            line-height: 20.654px;
            letter-spacing: 0.1px;
        }
    }
}
</style>
