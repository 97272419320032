<template>
    <div class="custom-question-tab">
        <div
            class="custom-question-item"
            v-for="(item, index) in questionsTypeData"
            :key="index"
        >
            <template v-if="item && item.dataLength">
                <div class="custom-question-item__header">
                    <span>{{ $t('Question Type') }}</span>
                    <strong>{{ $t(item.label) }}</strong>
                    <div class="question-count">{{ item.dataLength }}</div>
                </div>

                <div class="custom-question-item__content">
                    <template v-if="item.value ===
                        FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE &&
                        customQuestionsContainer.scoreImportanceQuestions.length">
                        <QuestionScoreImportanceItem
                            v-for="(question, index) in customQuestionsContainer.scoreImportanceQuestions"
                            :key="index"
                            :question="question"
                            :questionIndex="index"
                            :isSurveyQuestion="isSurveyQuestion"
                            :redirectLoading="redirectLoading"
                            @click="$emit('onClickQuestionItem', question)"
                            @mousedown="handleMouseDown($event, question)"
                        />
                    </template>

                    <template v-if="item.value ===
                        FeedbackSessionQuestionsTypeEnum.TEXT_ONLY &&
                        customQuestionsContainer.textOnlyQuestions.length">
                        <QuestionTextOnlyItem
                            v-for="(question, index) in customQuestionsContainer.textOnlyQuestions"
                            :key="index"
                            :question="question"
                            :questionIndex="index"
                            :isSurveyQuestion="isSurveyQuestion"
                            :redirectLoading="redirectLoading"
                            @click="$emit('onClickQuestionItem', question)"
                            @mousedown="handleMouseDown($event, question)"
                        />
                    </template>

                    <template v-if="item.value ===
                        FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE &&
                        customQuestionsContainer.multipleChoiceQuestions.length">
                        <QuestionMultipleChoiceItem
                            v-for="(question, index) in customQuestionsContainer.multipleChoiceQuestions"
                            :key="index"
                            :question="question"
                            :questionIndex="index"
                            :isSurveyQuestion="isSurveyQuestion"
                            :redirectLoading="redirectLoading"
                            @click="$emit('onClickQuestionItem', question)"
                            @mousedown="handleMouseDown($event, question)"
                        />
                    </template>

                    <template v-if="item.value ===
                        FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID &&
                        customQuestionsContainer.multipleChoiceGridQuestions.length">
                        <QuestionMultipleChoiceGridItem
                            v-for="(question, index) in customQuestionsContainer.multipleChoiceGridQuestions"
                            :key="index"
                            :question="question"
                            :questionIndex="index"
                            :isSurveyQuestion="isSurveyQuestion"
                            :redirectLoading="redirectLoading"
                            @click="$emit('onClickQuestionItem', question)"
                            @mousedown="handleMouseDown($event, question)"
                        />
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import QuestionTextOnlyItem from "@/buying-teams/shared-components/questions/QuestionTextOnlyItem";
import QuestionMultipleChoiceItem from "@/buying-teams/shared-components/questions/QuestionMultipleChoiceItem";
import QuestionMultipleChoiceGridItem from "@/buying-teams/shared-components/questions/QuestionMultipleChoiceGridItem";
import QuestionScoreImportanceItem from "@/buying-teams/shared-components/questions/QuestionScoreImportanceItem";
import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";
import {
    CustomQuestionsAnalyticsContainer
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsContainer";

export default {
    name: "CustomQuestionTab",
    components: {
        QuestionScoreImportanceItem,
        QuestionMultipleChoiceGridItem,
        QuestionMultipleChoiceItem,
        QuestionTextOnlyItem
    },
    emits: ['onClickQuestionItem'],
    props: {
        customQuestionsContainer: {
            type: [Object, CustomQuestionsAnalyticsContainer],
            default: null
        },
        isSurveyQuestion: {
            type: Boolean,
            default: false,
        },
        redirectLoading: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            FeedbackSessionQuestionsTypeEnum,
        }
    },
    computed: {
        questionsTypeData() {
            return [
                {
                    value: FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE,
                    label: 'score_importance',
                    dataLength: this.customQuestionsContainer.scoreImportanceQuestions.length,
                },
                {
                    value: FeedbackSessionQuestionsTypeEnum.TEXT_ONLY,
                    label: 'text_only',
                    dataLength: this.customQuestionsContainer.textOnlyQuestions.length,
                },
                {
                    value: FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE,
                    label: 'multiple_choice',
                    dataLength: this.customQuestionsContainer.multipleChoiceQuestions.length,
                },
                {
                    value: FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID,
                    label: 'multiple_choice_grid',
                    dataLength: this.customQuestionsContainer.multipleChoiceGridQuestions.length,
                }
            ]
        }
    },
    methods: {
        handleMouseDown(event, question) {
            if (event.which === 2) {
                this.$emit('onClickQuestionItem', question, true);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-question-tab {
    .custom-question-item {
        &__header {
            min-height: 57px;
            border-bottom: 1px solid #0000000F;
            display: flex;
            align-items: center;
            span {
                color: #757575;
                text-align: center;
                font-size: 14px;
                line-height: 12.654px;
                margin-right: 12px;
                opacity: 0.5;
            }
            strong {
                color: #FCAC12;
                font-size: 16px;
                font-weight: 800;
                line-height: 12.654px;
            }
            .question-count {
                border-radius: 3px;
                background: #ECECEC;
                color: #000;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                padding: 2px;
                min-width: 34px;
                margin-left: 12px;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }
}
</style>
