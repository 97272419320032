<template>
    <div class="questions-list questions-tabs">
        <div class="questions-tabs__loading" v-if="isLoading">
            <CustomLoader height="400px"/>
        </div>

        <div class="section-card-actions" v-if="activeTab === QUESTIONS_TABS.BENCHMARK_QUESTIONS">
            <el-select
                v-model="customersSorting"
                :disabled="!benchmarkQuestionsList.length"
                placeholder="Select"
                class="sort-select"
                @change="handleSortingChange"
            >
                <el-option
                    v-for="item in scoresLevels"
                    :key="item"
                    :label="item.label"
                    :value="item.key"
                />
            </el-select>
        </div>

        <el-tabs v-model="activeTab">
            <el-tab-pane
                :name="QUESTIONS_TABS.BENCHMARK_QUESTIONS"
                :class="{'redirectLoading': customQuestions.redirectLoading}"
            >
                <template #label>
                    <span>{{ $t('Benchmark Questions') }}</span>
                    <strong>{{ benchmarkQuestionsList.length }}</strong>
                </template>

                <div class="questions-list-content">

                    <BenchmarkQuestionTab
                        v-if="benchmarkQuestionsList.length"
                        :benchmark-question="benchmarkQuestionsList"
                        :isSurveyQuestion="isSurveyQuestion"
                        :redirectLoading="customQuestions.redirectLoading"
                        @onClickQuestionItem="handleQuestionClick"
                    />

                    <QuestionEmptyState v-else />

                </div>
            </el-tab-pane>

            <el-tab-pane
                :name="QUESTIONS_TABS.CUSTOM_QUESTIONS"
                :disabled="!customQuestions.questionsLength"
                :class="{'redirectLoading': customQuestions.redirectLoading}"
            >
                <template #label>
                    <span>{{ $t('Custom Questions') }}</span>
                    <strong>{{ customQuestions.questionsLength }}</strong>
                </template>

                <div class="questions-list-content">

                    <CustomQuestionTab
                        :custom-questions-container="customQuestions"
                        v-if="customQuestions.questionsLength"
                        :isSurveyQuestion="isSurveyQuestion"
                        :redirectLoading="customQuestions.redirectLoading"
                        @onClickQuestionItem="handleQuestionClick"
                    />

                    <QuestionEmptyState v-else />

                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import { scoresSortingLevels } from "@/buying-teams/pages/bank/dashboard/dashboardData";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import CustomQuestionTab from "@/buying-teams/shared-components/questions/CustomQuestionTab";
import BenchmarkQuestionTab from "@/buying-teams/shared-components/questions/BenchmarkQuestionTab";
import QuestionEmptyState from "@/buying-teams/shared-components/questions/QuestionEmptyState";
import {
    CustomQuestionsAnalyticsContainer
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsContainer";
import { QUESTIONS_TABS } from "@/core/config/constants";

export default {
    name: "QuestionsTabsContainer",
    components: {
        QuestionEmptyState,
        BenchmarkQuestionTab,
        CustomQuestionTab,
        CustomLoader,
    },
    emits: ['onClickQuestionItem', 'onTabChange', 'onSortingChange'],
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        benchmarkQuestionsList: {
            type: Array,
            default: []
        },
        customQuestions: {
            type: [Object, CustomQuestionsAnalyticsContainer],
            default: null
        },
        isSurveyQuestion: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeTab: QUESTIONS_TABS.BENCHMARK_QUESTIONS,
            QUESTIONS_TABS,
            customersSorting: "lowest_score",
        }
    },
    computed: {
        scoresLevels() {
            return scoresSortingLevels;
        }
    },
    methods: {
        handleQuestionClick(question, newTab) {
            this.$emit('onClickQuestionItem', question, newTab);
        },
        handleSortingChange(sorting) {
            this.$emit('onSortingChange', sorting);
        }
    },
    watch: {
        activeTab() {
            this.$emit('onTabChange', this.activeTab);
        }
    }
}
</script>

<style lang="scss">
.questions-tabs {
    width: 100%;
    min-height: 200px;
    position: relative;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
    border-radius: 17px;

    &__loading {
        position: absolute;
        z-index: 4;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #ffffffc2;
        backdrop-filter: blur(8px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .el-tab-pane {
        &.redirectLoading {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #ffffffc7;
                z-index: 2;
            }
        }
    }

    .el-tabs__header {
        padding: 13px 23px 0 13px;
        margin-bottom: 0;
    }
    .el-tabs--top .el-tabs__item {
        height: 60px;
        display: inline-flex;
        align-items: center;
        > span {
            font-weight: 700;
            font-size: 18px;
            line-height: 13px;
            color: #000000;
            opacity: 0.87;
            margin: 0 8px 0 11px;
        }
        > strong {
            background: #ECECEC;
            border-radius: 3px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            padding: 3px;
        }
        &.is-top:nth-child(2) {
            margin-left: 11px;
        }
        &.is-active {
            > span {
                color: #435BF4;
            }
            > strong {
                background: #435BF4;
                color: #fff;
            }
            > svg path {
                fill: #435BF4;
            }
        }
        &.is-disabled {
            opacity: 0.4;
            > * {
                user-select: none;
            }
        }
    }

    .questions-list-content {
        padding: 0 20px;
    }

    .section-card-actions {
        position: absolute;
        top: 17px;
        right: 29px;
        z-index: 3;
        .el-input__inner {
            width: 220px;
            height: 35px !important;
        }
    }
}
</style>
