<template>
    <div class="benchmark-question">
        <QuestionScoreImportanceItem
            v-for="(question, index) in benchmarkQuestion"
            :key="index"
            :questionIndex="index"
            :question="question"
            :isSurveyQuestion="isSurveyQuestion"
            :redirectLoading="redirectLoading"
            @click="handleClickQuestionItem(question)"
            @mousedown="handleMouseDown($event, question)"
        />
    </div>
</template>

<script>
import QuestionScoreImportanceItem from "@/buying-teams/shared-components/questions/QuestionScoreImportanceItem";

export default {
    name: "BenchmarkQuestionTab",
    emits: ['onClickQuestionItem'],
    props: {
        benchmarkQuestion: {
            type: [Array],
            required: true
        },
        isSurveyQuestion: {
            type: Boolean,
            default: false,
        },
        redirectLoading: {
            type: Number,
            default: null,
        },
    },
    components: {
        QuestionScoreImportanceItem
    },
    methods: {
        handleClickQuestionItem(question) {
            this.$emit('onClickQuestionItem', question);
        },
        handleMouseDown(event, question) {
            if (event.which === 2) {
                this.$emit('onClickQuestionItem', question, true);
            }
        }
    }
}
</script>
