<template>
  <div
    class="question-info-item"
    :class="{
      [getStatusClass(this.data)]: itemKey === RatingTypesEnum.SCORE,
      [`${getGapStatusClass(this.data)}-border`]: itemKey === RatingTypesEnum.GAP
    }"
  >
    <div class="question-info-item--header">
      <span class="title">
      {{ numberDecimalsFormat(data) }}
      </span>
      <div class="text-center" :class="{'green-type': percent < 0.4, 'red-type': percent >= 0.4}">
        <!--        <span>{{ percent }}%</span>-->
        <!--        <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--          <path-->
        <!--            d="M2.83159 0.890084C3.12846 0.290198 3.98401 0.290199 4.28087 0.890085L6.82975 6.04066C7.09568 6.57804 6.70469 7.20778 6.1051 7.20778H1.00736C0.407773 7.20778 0.0167809 6.57804 0.282718 6.04066L2.83159 0.890084Z" />-->
        <!--        </svg>-->
      </div>
    </div>
    <div class="question-info-item--content">
      <div class="question-info-label">
        <span :class="`question-info-label--${itemKey}`">
          {{ name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatusClass, getGapStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import RatingTypesEnum from "@/store/enums/RatingTypesEnum";


export default {
  name: "QuestionInfoItemStats",
  props: {
    data: [Number, String],
    name: String,
    itemKey: String,
    percent: Number
  },
  data() {
    return {
      getStatusClass,
      getGapStatusClass,
      numberDecimalsFormat,
      RatingTypesEnum,
    };
  }
}
</script>

<style scoped lang="scss">

.question-info-item {
  &.status-danger-border {
    border-bottom: 4px solid #E22D21;
    .question-info-item--content .question-info-label--gap:after {
      background: #E22D21;
    }
  }
  &.status-success-border {
    border-bottom: 4px solid #2BC490;
  }
  &.status-danger {
    border-bottom: 4px solid #E22D21;
  }
  &.status-warning {
    border-bottom: 4px solid #FBDC6C;
  }
  &.status-success {
    border-bottom: 4px solid #2BC490;
  }
  &--header {
    display: flex;
    align-items: center;

    .title {
      font-weight: 700;
      font-size: 16px;
      color: #000000;
      opacity: 0.87;
      margin-right: 4px;
    }

    .green-type {
      font-size: 10px;

      span {
        color: #1B9F72;
      }

      svg {
        fill: #2BC490;
      }
    }

    .red-type {
      span {
        color: #E22D21FF;
      }

      svg {
        fill: #E22D21;
        transform: rotate(180deg);
      }
    }
  }

  &--content {
    display: flex;
    margin-bottom: 8px;
    .question-info-label {
      margin-right: 20px;

      h4 {
        font-weight: 700;
        font-size: 14px;
        line-height: 13px;
        color: #000000B2;
      }

      span {
        font-weight: 400;
        font-size: 12.905px;
        line-height: 16px;
        text-align: center;
        color: #0000007F;
        padding-left: 16px;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      &--score:after {
        background: #435BF4;
      }

      &--importance:after {
        background: #CED4F8;
      }

      &--gap:after {
        background: #2BC490;
      }
    }
  }
}
</style>
