<template>
    <div class="question-info">
        <div class="info row">
            <div class="info-items d-flex justify-content-between col-lg-9">
                <QuestionInfoItemStats
                    v-for="(stats, index) in questionStats"
                    :key="index"
                    :itemKey="stats.key"
                    :name="stats.title"
                    :data="stats.value"
                />
            </div>
            <div class="col-lg-3">
                <div class="info-items--comments-button"
                     :class="{'info-items--comments-button-disabled': +question.comments_count <= 0}">
                    <img src="/media/buying/icons/comment-blue.svg" width="14" alt="">

                    <span>{{ question.commentsCount }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {ChatLineSquare} from "@element-plus/icons-vue";
import QuestionInfoItemStats from "@/buying-teams/shared-components/feedbacks/QuestionInfoItemStats";
import RatingTypesEnum from "@/store/enums/RatingTypesEnum";

export default {
    name: "QuestionInfo",
    components: {QuestionInfoItemStats},
    props: {
        question: Object
    },
    data() {
        return {
            ChatLineSquare
        };
    },
    computed: {
        questionStats() {
            return [
                {
                    key: RatingTypesEnum.SCORE,
                    title: this.$t('Score'),
                    value: this.question.score
                },
                {
                    key: RatingTypesEnum.IMPORTANCE,
                    title: this.$t('Importance'),
                    value: this.question.importance
                },
                {
                    key: RatingTypesEnum.GAP,
                    title: this.$t('Gap'),
                    value: this.question.gap
                }
            ]
        }
    }
};

</script>

<style scoped lang="scss">
.question-info {
    .info-items {
        border: 1px solid #B9B9B9;
        border-radius: 5px;
        padding: 17px 26px 0;

        &--comments-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #F6F7FF;
            border-radius: 5px;
            height: 100%;
            padding: 13px 25px;
            cursor: pointer;
            width: 100%;

            span {
                font-weight: 700;
                font-size: 16px;
                color: #000000;
                margin-left: 8px;
            }

            &-disabled {
                background: #F9F9F9;
                border-radius: 5px;

                span {
                    color: #C1C1C1;
                }
            }

            &:hover {
                background: #435BF4;

                span {
                    color: #fff;
                }

                img {
                    filter: brightness(0) invert(1);
                }
            }
        }

        @media (max-width: 991px) {
            &--comments-button {
                margin-top: 16px;

                .row {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
            }
        }
    }

}
</style>

