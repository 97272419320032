import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-bar-chart" }
const _hoisted_2 = { class: "custom-bar-chart__row" }
const _hoisted_3 = { class: "custom-bar-chart__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tooltip, {
        "popper-class": "custom-bar-chart__tooltip",
        content: `${_ctx.score.value} ${_ctx.$t('Score')}`,
        "show-arrow": true,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "custom-bar-chart__line",
            style: _normalizeStyle({ width: _ctx.score.width + '%', backgroundColor: _ctx.score.color })
          }, null, 4)
        ]),
        _: 1
      }, 8, ["content"]),
      (!_ctx.positive)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            "popper-class": "custom-bar-chart__tooltip",
            content: `${_ctx.gap.value} ${_ctx.$t('Gap')}`,
            "show-arrow": true,
            placement: "top",
            effect: "light"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "custom-bar-chart__line progress-bar progress-bar-striped bg-danger line-gap",
                role: "progressbar",
                style: _normalizeStyle({ width: _ctx.gap.width + '%', backgroundColor: _ctx.gap.color }),
                "aria-valuenow": "100",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }, null, 4)
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_tooltip, {
        "popper-class": "custom-bar-chart__tooltip",
        content: `${_ctx.importance.value} ${_ctx.$t('Importance')}`,
        "show-arrow": true,
        placement: "top",
        effect: "light"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "custom-bar-chart__line",
            style: _normalizeStyle({ width: _ctx.importance.width + '%', backgroundColor: _ctx.importance.color })
          }, null, 4)
        ]),
        _: 1
      }, 8, ["content"]),
      (_ctx.positive)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            "popper-class": "custom-bar-chart__tooltip",
            content: `${_ctx.gap.value} ${_ctx.$t('Gap')}`,
            "show-arrow": true,
            placement: "top",
            effect: "light"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "custom-bar-chart__line progress-bar progress-bar-striped",
                role: "progressbar",
                style: _normalizeStyle({ width: _ctx.gap.width + '%', backgroundColor: _ctx.gap.color }),
                "aria-valuenow": "100",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }, null, 4)
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true)
    ])
  ]))
}