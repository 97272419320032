
import { defineComponent, PropType } from "vue"
import { BarChartItem } from "@/store/models/BarChartModel";

export default defineComponent({
  name: "CustomBarChart",
  props: {
    positive: {
      type: Boolean,
      default: true
    },
    score: {
      type: Object as PropType<BarChartItem>,
      required: true
    },
    importance: {
      type: Object as PropType<BarChartItem>,
      required: true
    },
    gap: {
      type: Object as PropType<BarChartItem>,
      required: true
    }
  }
})
