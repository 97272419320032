<template>
    <div class="question-empty-state">
        <img src="/media/buying/icons/no-questions-found.svg" alt="">
        <h4>{{ $t('No questions to show') }}</h4>
        <p>{{ $t('Your company don\'t have any data for this bank. All questions reviewed will be shown here. Create a feedback session to start reviewing and get insights into your banks performance.') }}</p>
    </div>
</template>

<script>
export default {
    name: "QuestionEmptyState"
}
</script>

<style scoped lang="scss">
.question-empty-state {
    max-width: 500px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
    > img {
        margin-bottom: 20px;
    }
    h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #D4D4D4;
        margin-bottom: 8px;
    }
    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        color: #D4D4D4;
        margin-bottom: 25px;
    }
    a {
        background: #F6F7FE;
        border-radius: 53px;
        height: 37px;
        padding: 11px 36px;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
            font-weight: 700;
            font-size: 14px;
            line-height: 13px;
            color: #435BF4;
        }
        &:hover {
            background: #eeefff;
        }
    }
}
</style>
